.title {
    font-size: 7vw;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;

}
.header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    height: 35vh;
    width: 100%;
}
@media (max-width: 900px) {
    .header {
        height: 25vh;
    }
    .title {
        font-size: 11vw
    }
}
.inputs {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.search, .select {
    width: 43%
}